import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FeedESTDateToString, formatDate2, formatDateAPI, formatDateString } from "../../functions/format/date";
import '../../styles/editFormIntake.css';
import LoadingOverlay from "../../containers/loading/loadingOverlay";
import { BoldListField, MainListField } from "../../containers/listFields/mainListFields";
import DownloadIcon from '../../assets/images/download-icon.svg'
import { capitalizeFirst } from "../../functions/stringFormat";
import ReceivedApproval from "../../components/receivedApproval";
import PageNotFound from "../pageNotFound";
import AdminCommentV2 from "../../components/adminCommentV2";
import FieldHistory from "../../components/FieldHistory";
import { checkTitle, dataRequestTypeList, numberFormat } from "../../containers/FixedData/fixedData";

const ViewFromIntakeV2 = (props) => {
    const { dataNew, loading, fetchViewByIDs } = props
    const [compaignData, setCompaignData] = useState(null)
    const [getCampaignId, setGetCampaignId] = useState('')
    const [countData, setCountData] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const [dataEnity, setDataEnity] = useState(false);
    const [warningQA, SetWarningQA] = useState([])

    useEffect(() => {
        if (searchParams.get('cam')) {
            setGetCampaignId(searchParams.get('cam'))
            fetchViewByID(dataNew);
        } else {
            console.log("Fail!")
        }
    }, [dataNew])


    const fetchViewByID = (dataNew) => {

        let data = dataNew;
        let i = 0;
        SetWarningQA(dataNew)
        if (data) {
            data && data.legalDeclaimer && data.legalDeclaimer.map((item) => {
                if (item.isCheck === 'no') {
                    i++;
                }
            });
            data && data.campaignOffer && data.campaignOffer.map((item) => {
                if (item.isCheck === 'no') {
                    i++;
                }
            });
            data && data.campaignIdea && data.campaignIdea.map((item) => {
                if (item.isCheck === 'no') {
                    i++;
                }
            });
            data && data.addKeyMessage && data.addKeyMessage.map((item) => {
                item.channelCount = item.channel.filter(co => co.isCheck === 'yes').length
            })
            setCompaignData(data);
        } else {
            setDataEnity(true);
        }
        setCountData(i);

    }
    const sliceURL = (sliceURL) => {
        let fileNameUpload = sliceURL;
        let parser = document.createElement('a');
        parser.href = fileNameUpload;
        let fileName = parser.pathname.split('/').slice(1);
        return fileName.pop()
    };

    const getFileNameShort = (sliceURL) => {
        if (sliceURL.length > 30) {
            return sliceURL.substr(0, 30) + '...' + sliceURL.substr(-11)
        }

        return sliceURL
    }

    let desiredMetricsIndex = 0;
    let personalizationInndex = 0;
    let commentIndex = 0;
    let donorType = 0;
    let locationIndex = 0;
    let personalIndex = 0;
    if (dataEnity) {
        return <PageNotFound />
    }


    let contentDesignAssetNew = compaignData && compaignData.contentDesignAsset && compaignData.contentDesignAsset.length > 0 ? compaignData.contentDesignAsset.filter(it => it.url) : []
    let contentDesignAssetData = contentDesignAssetNew.length > 0 ? [
        {
            "keyLanguage": "English",
            "files": contentDesignAssetNew
        }
    ] : compaignData && compaignData.contentDesignAsset && compaignData.contentDesignAsset.length > 0 ? compaignData.contentDesignAsset : []

    let designAssetLeverageNew = compaignData && compaignData.designAssetLeverage && compaignData.designAssetLeverage.length > 0 ? compaignData.designAssetLeverage.filter(it => it.url) : []
    let designAssetLeverageData = designAssetLeverageNew.length > 0 ? [
        {
            "keyLanguage": "English",
            "files": contentDesignAssetNew
        }
    ] : compaignData && compaignData.designAssetLeverage && compaignData.designAssetLeverage.length ? compaignData.designAssetLeverage : []

    const languageApplyCheckedShowText = (type) => {
        let types = ['US - Spanish', 'Puerto Rico - Spanish']
        let typeCheck = compaignData && compaignData.languageApply && compaignData.languageApply.filter(it => it.isCheck === "yes" && types.includes(it.type))
        return typeCheck && typeCheck.length > 0 ? true : false
    }
    const filteredCampaignOffers = compaignData && compaignData.campaignOffer?.filter(item => item.isCheck === 'yes');


    return (
        <div className="container" style={{ marginTop: '120px', marginBottom: '120px' }}>
            {/* Global LoadingOverlay */}
            {/* {loading ? <LoadingOverlay /> : null} */}
            <div style={{ marginBottom: '50px' }}>
                <h3 className="FormIntakeH3" style={{ marginBottom: '8px' }}>{checkTitle(compaignData)}</h3>
                <p className="size18700 mb-0">Submission Date: {compaignData ? formatDateAPI(compaignData.submitDate) : ''}</p>
                {/* <p className='size14520 mb-0'>Please be advised: Digital campaigns take approximately 2 weeks from approval to deployment.</p> */}
                {
                    compaignData && compaignData.reSubmitDate ? <p className="size16700 mb-0 mt-1" style={{ fontWeight: 700 }}>Resubmitted on: {compaignData ? formatDateAPI(compaignData.reSubmitDate) : ''}</p>
                        : ''
                }
            </div>
            <div className="d-flex">
                <div className="col-12 col-lg-6 col-sm-12">
                    {countData > 0 && <ReceivedApproval
                        dataNew={dataNew}
                        warningQA={warningQA}
                        fetchViewByID={fetchViewByID}
                        fetchViewByIDs={fetchViewByIDs}
                        getCampaignId={getCampaignId}
                    />}
                </div>
                {/* History */}
                <div className="col-12 col-lg-6 col-sm-12 admin-comments">
                    <FieldHistory compaignData={compaignData} />
                </div>
            </div>
            {/* Stakeholder/Requester */}
            <div>
                <div className="row">
                    <div className="col-lg-6 col-sm-12" style={{ marginTop: countData > 0 && compaignData && compaignData.isHideWarning === "no" ? 0 : "-280px" }}>
                        {/* Stakeholder/Requester */}
                        <p className="titleStakeholder mb-40px">Stakeholder/Requester</p>
                        <div className="row gy-40px">
                            <div className="col-md-8 col-sm-12">
                                <MainListField label="First Name" value={compaignData ? compaignData.firstName : ''} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <MainListField label="Last Name" value={compaignData ? compaignData.lastName : ''} />
                            </div>
                            <div className="col-md-8 col-sm-12">
                                <MainListField label="Email" value={compaignData ? compaignData.email : ''} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <MainListField label="Department" value={compaignData ? compaignData.department : ''} />
                            </div>
                        </div>
                        <div className="csl-red-line"></div>
                        <p className="titleStakeholder mb-40px">Request Types
                            <p className='size16520 mb-0'>{compaignData ? dataRequestTypeList(compaignData.requestType) : ''}</p>
                            {
                                compaignData && compaignData.requestType === "technical-ticket" ? "" :
                                    <p className='size16520 mb-0'>{compaignData ? compaignData.adhocEvergreen && compaignData.adhocEvergreen.title : ''}</p>
                            }
                        </p>
                        {
                                    compaignData && compaignData.requestType === "technical-ticket" ?
                                        <>
                                            <div className="col-12 mb-40px">
                                                {compaignData && compaignData.summaryTitle &&<BoldListField label="Issue Title*" value={compaignData ? compaignData.summaryTitle : ''} />}
                                            </div> 
                                            <div className="col-12 mb-40px">
                                                <BoldListField label="Summary of issue*" value={compaignData ? compaignData.summaryIssue : ''} />
                                            </div> 
                                        </>
                                        : ""
                                }
                        {
                            <div className="col-12 mb-40px">
                                {compaignData && compaignData.dateDiscovered || compaignData && compaignData.dateResolution ? (
                                    <BoldListField
                                        label="Date of issue discovered*"
                                        value={`${compaignData && compaignData.dateDiscovered ? formatDateString(compaignData && compaignData.dateDiscovered.slice(0, 10)) : ''}`}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        }
                        {
                            <div className="col-12 mb-40px">
                                {compaignData && compaignData.dateDiscovered || compaignData && compaignData.dateResolution ? (
                                    <BoldListField
                                        label="Due date for desired resolution*"
                                        value={`${compaignData && compaignData.dateResolution ? formatDateString(compaignData && compaignData.dateResolution.slice(0, 10)) : ''}`}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        }
                        {
                            compaignData && compaignData.requestType === "technical-ticket" ?
                                <div className="col-12">
                                    {compaignData && compaignData.additionalDocumentation.length > 0 ? (
                                        <div className="d-flex flex-column">
                                            <BoldListField
                                                label="Any additional documentation to share for this request?"
                                            />
                                            <div className="upload-file-body mt-2">
                                                <div className="d-flex flex-row align-items-center justify-content-between">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <img src={DownloadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                                        {
                                                            compaignData && compaignData.additionalDocumentation.length > 0 ? '' : <p className="size16700 mb-0">No File</p>
                                                        }
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        {
                                                            compaignData && compaignData.additionalDocumentation ? compaignData.additionalDocumentation.map((item, index) => (
                                                                <div className="d-flex flex-row align-items-center justify-content-end" style={{ position: 'relative', zIndex: '10' }}>
                                                                    <a target="_blank" href={item.url} className="mb-0 design-upload">{getFileNameShort(sliceURL(item.url).slice(14))}</a>
                                                                </div>
                                                            )) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div> : ""
                        }
                        {
                            compaignData && compaignData.requestType === "campaign-requests" ?
                                <>
                                    {/* Campaign Details */}
                                    <p className="titleStakeholder mb-40px">Campaign Details</p>
                                    <div className="row gy-30px">
                                        <div className="col-12">
                                            <BoldListField label="Campaign Title*" value={compaignData ? compaignData.title : ''} />
                                        </div>
                                        <div className="col-12 mb-40px">
                                            <BoldListField label="" value=
                                                {
                                                    compaignData ? compaignData.addKeyMessage && compaignData.addKeyMessage.map((item) => {
                                                        let inch = 0;
                                                        let chArr = item.channel;
                                                        item.countChannels = chArr.filter(it => it.isCheck === 'yes').length
                                                        return (
                                                            <div className="col-12">
                                                                <div className="d-flex flex-column">
                                                                    <label htmlFor="basic-url" className="size18700">Campaign Goal / Key Message*</label>
                                                                    <p className="" style={{ fontSize: '16px', marginBottom: '16px' }}>{`${item.keyMessage}`}</p>
                                                                    <label htmlFor="basic-url" className="infoLabel1">Channels</label>
                                                                    <p className="" style={{ fontSize: '16px' }}>
                                                                        {
                                                                            item ? item.channel.map((ch) => {
                                                                                if (ch.isCheck === 'yes') {
                                                                                    inch++;
                                                                                    return (`${ch.type === 'sms' ? ch.type.toUpperCase() : capitalizeFirst(ch.type=== "app" ? "CSL Plasma App" : ch.type )}${inch === item.countChannels ? '' : ','} `);
                                                                                }
                                                                            }) : ''}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : null
                                                }
                                            />
                                        </div>
                                        <div className="col-12">
                                            <BoldListField
                                                label="Campaign Duration*"
                                                value={`${formatDateString(compaignData ? compaignData.startDuration && compaignData.startDuration.slice(0, 10) : '')} — ${formatDateString(compaignData ? compaignData.endDuration && compaignData.endDuration.slice(0, 10) : '')}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="csl-red-line"></div>
                                    {/* Audience Details */}
                                    <p className="titleStakeholder mb-40px">Audience</p>
                                    <div className="row gy-40px">
                                        <div className="col-12">
                                            <p htmlFor="basic-url" className="size18700">Initial Target Audience*</p>
                                            <div className="d-flex flex-column">
                                                {
                                                    compaignData && compaignData.targetAudience ? compaignData.targetAudience.map((item) => {
                                                        if (item.inputType === "donor-type") {
                                                            const donorTypes = item.donorType.filter(fd => fd != 'Other');
                                                            const isOther = item.donorType.find(item => item == 'Other');
                                                            return (
                                                                <div style={{ marginTop: '16px' }}>
                                                                    <label htmlFor="" className="infoLabel1">Donor Type</label>
                                                                    <p className="" style={{ fontSize: '16px' }}>
                                                                        {donorTypes.join(', ')}
                                                                    </p>
                                                                    {(isOther === 'Other' && item.additionalText) &&
                                                                        <p className="" style={{ fontSize: '16px' }}>Other: {item.additionalText}</p>
                                                                    }
                                                                </div>
                                                            )
                                                        } else if (item.inputType === "donation-history") {
                                                            return (
                                                                <div style={{ marginTop: '16px' }}>
                                                                    <label htmlFor="basic-url" className="infoLabel1">Donation History</label>
                                                                    {
                                                                        item.noDonation === "yes" ? <p>No Donation</p> : ''
                                                                    }
                                                                    {
                                                                        item.donationAmount.enable === "yes" ? <p className="" style={{ fontSize: '16px' }}>Donation Amount:
                                                                            {
                                                                                item.donationAmount.key === "between" ? ` Between ${item.donationAmount.value[0] ? item.donationAmount.value[0] : ""} - ${item.donationAmount.value[1] ? item.donationAmount.value[1] : ""}` : ""
                                                                            }
                                                                            {
                                                                                item.donationAmount.key === "less-then" ? ` Less Than ${item.donationAmount.value[0] ? item.donationAmount.value[0] : ""}` : ""
                                                                            }
                                                                            {
                                                                                item.donationAmount.key === "greater-then" ? ` Greater Than ${item.donationAmount.value[0] ? item.donationAmount.value[0] : ""}` : ""
                                                                            }
                                                                        </p> : ""
                                                                    }
                                                                    {
                                                                        item.donationDate.enable === "yes" ? <p className="" style={{ fontSize: '16px' }}>Donation Date:
                                                                            {
                                                                                item.donationDate.key === "before" ? ` Before ${item.donationDate.value[0] ? formatDate2(item.donationDate.value[0]) : ""}` : ""
                                                                            }
                                                                            {
                                                                                item.donationDate.key === "after" ? ` After ${item.donationDate.value[0] ? formatDate2(item.donationDate.value[0]) : ""}` : ""
                                                                            }
                                                                            {
                                                                                item.donationDate.key === "between" ? ` Between ${item.donationDate.value[0] ? formatDate2(item.donationDate.value[0]) : ""} - ${item.donationDate.value[1] ? formatDate2(item.donationDate.value[1]) : ""}` : ""
                                                                            }
                                                                        </p> : ""
                                                                    }
                                                                    {
                                                                        item.other === "yes" && item.additionalText ? <p className="" style={{ fontSize: '16px' }}>Other: {item.additionalText}</p> : ""
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        else if (item.inputType === "location") {
                                                            return (
                                                                <div style={{ marginTop: '16px' }}>
                                                                    <label htmlFor="basic-url" className="infoLabel1">Location</label>
                                                                    {
                                                                        item.allCenter === 'yes' ? <p style={{ fontSize: '16px' }}>{`${!item.typeCenter && !item.typePrCenter ? "All US Centers" : `${item.typeCenter === 'all_us_centers' ? 'All US Centers' : ""}${item.typeCenter === 'all_us_centers' && item.typePrCenter === 'all_pr_centers' ? "," : ""} ${item.typePrCenter === 'all_pr_centers' ? 'All PR Centers' : ""} `}`}</p>
                                                                            :
                                                                            <p className="" style={{ fontSize: '16px' }}>{
                                                                                item.centers ? item.centers.map((lo) => {
                                                                                    locationIndex++;
                                                                                    return `${(lo)}${locationIndex === item.centers.length ? '' : ', '}`
                                                                                }) : ''
                                                                            }</p>
                                                                    }
                                                                    {
                                                                        item.other === "yes" && item.additionalText ?
                                                                            <p className="" style={{ fontSize: '16px' }}>Other: {item.additionalText}</p>
                                                                            : ''
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    }) : null}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <BoldListField label="Audience List" value={compaignData ? compaignData.audienceException : ''} />
                                        </div>
                                        <div className="col-12 mt-2">
                                            <div className="d-flex flex-column">
                                                <div className="upload-file-body">
                                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <img src={DownloadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                                            {
                                                                compaignData && compaignData.audienceExceptionAsset.length > 0 ? '' : <p className="size16700 mb-0">No File</p>
                                                            }
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            {
                                                                compaignData && compaignData.audienceExceptionAsset ? compaignData.audienceExceptionAsset.map((item, index) => (
                                                                    <div className="d-flex flex-row align-items-center justify-content-end" style={{ position: 'relative', zIndex: '10' }}>
                                                                        <a target="_blank" href={item.url} className="mb-0 design-upload">{getFileNameShort(sliceURL(item.url).slice(14))}</a>
                                                                    </div>
                                                                )) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <BoldListField label="Does this campaign have a control group?*" value={compaignData && compaignData.controlGroup === "yes" ? "Yes" : 'No'} />
                                        </div>
                                        {
                                            compaignData && compaignData.controlGroup === "yes" ?
                                            <div className="col-12">
                                                <label htmlFor="basic-url" className="infoLabel1">List count of control group*</label>
                                                <div>
                                                    <p style={{ fontSize: '16px' }}>{compaignData ? numberFormat(compaignData.totalApproximate) : ''}</p>
                                                </div>
                                            </div> : ""
                                        }
                                    </div>
                                    <div className="csl-red-line"></div>
                                    {/* Content Details */}
                                    <div className="">
                                        <p className="titleStakeholder mb-40px">Content</p>
                                        <p htmlFor="basic-url" className="size18700">This request is for </p>
                                        <p className="" style={{ fontSize: '16px', marginBottom: '16px' }}>{compaignData && compaignData.contentRequest}</p>
                                        <div className="row gy-40px">
                                            {compaignData && compaignData?.languageApply?.some(item => item.isCheck === 'yes') && (
                                                <div className="col-12">
                                                    <div className="d-flex flex-column">
                                                        <label htmlFor="basic-url" className="size18700">Language</label>
                                                        <p className="" style={{ fontSize: '16px' }}>
                                                            {compaignData.languageApply
                                                                .filter(item => item.isCheck === 'yes')
                                                                .map(item => item.type)
                                                                .join(', ')}
                                                        </p>
                                                        {languageApplyCheckedShowText() && (
                                                            <p className="" style={{ fontSize: '16px' }}>
                                                                If your content features Spanish, please have your translation available when submitting this request. If you need support in transcreation, add ~5 business days to timeline.
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="csl-red-line"></div>
                                    {/* Design */}
                                    {/* <div className="">
                                        <p className="titleStakeholder mb-40px">Design</p>
                                        <div className="row gy-40px">
                                            <div className="col-12">
                                                <label htmlFor="basic-url" className="size18700">Where will the content be applied?</label>
                                                <p className="" style={{ fontSize: '16px' }}>{compaignData ? compaignData.contentApply : ''}</p>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex flex-column">
                                                    {
                                                        compaignData && compaignData?.campaignIdea
                                                            ?.map((item, index) => {
                                                                return (
                                                                    <div className="col-12" key={index}>
                                                                        <div className="d-flex flex-column">
                                                                            <label htmlFor="basic-url" className="size18700">Has the campaign idea and proposed illustration been approved by the marketing team and legal?*</label>
                                                                            <p className="" style={{ fontSize: '16px', marginBottom: '16px' }}>{item.type}</p>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                    }
                                                </div>
                                            </div>
                                            {
                                                compaignData?.campaignIdea
                                                    ?.filter(item => item.isCheck === 'yes') // Filter items where isCheck is 'yes'
                                                    ?.length > 0 && ( // Check if there are any filtered items
                                                    <div className="col-12">
                                                        <label htmlFor="basic-url" className="size18700">Please list the people who have approved this campaign*</label>
                                                        <p className="" style={{ fontSize: '16px' }}>{compaignData.peopleApprove || ''}</p>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                    <div className="csl-red-line"></div> */}
                                    {/* Campaign Mandatories */}
                                    <p className="titleStakeholder mb-40px">Campaign Mandatories</p>
                                    <div className="row gy-40px mb-40px">
                                        <div className="col-12">
                                            <label htmlFor="basic-url" className="size18700">Does the campaign have an offer?*</label>
                                            <p className="" style={{ fontSize: '16px' }}>
                                            {compaignData ? compaignData.campaignHaveOffer && compaignData.campaignHaveOffer.type :""}
                                            </p>
                                        </div>
                                        {
                                            compaignData && compaignData.campaignHaveOffer && compaignData.campaignHaveOffer.isCheck === "no" ? "" :
                                        <div style={{ marginTop: '16px' }}>
                                            <label htmlFor="basic-url" className="infoLabel1">Has the campaign offer/budget been approved?*</label>
                                            <div>
                                                <p style={{ fontSize: '16px' }}>
                                                    {compaignData ? compaignData.campaignOffer && compaignData.campaignOffer.map((item) => {
                                                        return (`${capitalizeFirst(item.type)} `);
                                                    }) : ''}
                                                </p>
                                                <p style={{ fontSize: '16px' }}>
                                                    {
                                                        filteredCampaignOffers.length === 0 ? "Budget needs to be approved prior to submitting this request as the request will be automatically declined without budget approval." : ""
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        }
                                        {compaignData && compaignData.campaignHaveOffer && compaignData.campaignHaveOffer.isCheck === "yes" && filteredCampaignOffers.length > 0 ?
                                            <div style={{ marginTop: '0px' }}>
                                                {/* <label htmlFor="basic-url" className="infoLabel1">Approved by</label>
                                                <div>
                                                    <p style={{ fontSize: '16px' }}>{compaignData ? compaignData.nameApprover : ""}</p>
                                                </div> */}
                                                <label htmlFor="basic-url" style={{ fontSize: '16px'}}>{compaignData && compaignData.nameApprover ? `Approved by ${compaignData && compaignData.nameApprover}` : ''}</label>
                                            </div> : ""
                                        }
                                        {/* <div className="col-12">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="basic-url" className="size18700">Does your campaign require a disclaimer?*</label>
                                                <p className="" style={{ fontSize: '16px' }}>
                                                    {compaignData && compaignData.legalDeclaimer ? compaignData.legalDeclaimer && compaignData.legalDeclaimer.map((item) => {
                                                        return (`${capitalizeFirst(item.type)} `);
                                                    }) : ''}
                                                </p>
                                                <div style={{ marginTop: '0px' }}>
                                                    <label htmlFor="basic-url" className="size18700">Has your disclaimer been approved by legal?*</label>
                                                    <div>
                                                        <p style={{ fontSize: '16px' }}>{compaignData && compaignData.haveApproved.type}</p>
                                                    </div>
                                                </div>
                                                {
                                                    compaignData && compaignData.legalDeclaimer && compaignData.legalDeclaimer.map((item) => (
                                                        <>
                                                            {
                                                                item && item.isCheck === 'yes' ?
                                                                    <div style={{ marginTop: '16px' }}>
                                                                        <label htmlFor="basic-url" className="size18700">Has your disclaimer been approved by legal?*</label>
                                                                        <div>
                                                                            <p style={{ fontSize: '16px' }}>{compaignData && compaignData.haveApproved.type}</p>
                                                                        </div>
                                                                    </div> : ""
                                                            }
                                                        </>
                                                    ))
                                                }
                                                { compaignData && compaignData.haveApproved && compaignData.haveApproved.isCheck ==='no' ? <label htmlFor="basic-url" style={{ fontSize: '16px'}}>Legal disclaimer needs to be approved prior to submitting this request as the request will be automatically declined without legal approval.</label> :""}
                                                {
                                                    compaignData && compaignData.haveApproved && compaignData.haveApproved.isCheck == 'yes' ? 
                                                    <label htmlFor="basic-url" style={{ fontSize: '16px'}}>{compaignData && compaignData.haveApproved && compaignData.haveApproved.insertName ? `Approved by ${compaignData && compaignData.haveApproved && compaignData.haveApproved.insertName}` : ''}</label> :
                                                    <label htmlFor="basic-url" style={{ fontSize: '16px'}}>{compaignData && compaignData.haveApproved && compaignData.haveApproved.isConfirm ? `I confirm that this message does not require legal approval and assume responsibility of any inaccuracies or errors in message` : ''}</label>
                                                }
                                                {
                                                    compaignData ? compaignData.legalDeclaimer && compaignData.legalDeclaimer.map((item) => {
                                                        if (item.isCheck == 'yes' && compaignData.haveApproved) {
                                                            if (compaignData.haveApproved.isCheck == 'yes') {
                                                                return <label htmlFor="basic-url" style={{ fontSize: '16px'}}>{compaignData.haveApproved.insertName ? `Approved by ${compaignData.haveApproved.insertName}` : ''}</label>
                                                            } else if (compaignData.haveApproved.isCheck == 'no') {
                                                                return <label htmlFor="basic-url" style={{ fontSize: '16px'}}>{compaignData.haveApproved.isConfirm ? `I confirm that this message does not require legal approval and assume responsibility of any inaccuracies or errors in message` : ''}</label>
                                                            } else {
                                                                return '';
                                                            }
                                                        }
                                                    }) : ''
                                                }
                                            </div>
                                        </div> */}
                                        <div className="col-12">
                                            <label htmlFor="basic-url" className="size18700">Does the campaign need a legal disclaimer?*</label>
                                            <p className="text-capitalize" style={{ fontSize: '16px' }}>
                                                {compaignData && !compaignData.needLegalDeclaimer && compaignData.haveApproved?.type ? "Yes" : ""}
                                                {compaignData ? compaignData && compaignData.needLegalDeclaimer : ""}
                                            </p>
                                        </div>
                                        {
                                            compaignData && compaignData.needLegalDeclaimer === "yes" ?
                                                <div style={{ marginTop: '16px' }}>
                                                    <div className="d-flex flex-column">
                                                        <div style={{ marginTop: '0px' }}>
                                                            <label htmlFor="basic-url" className="size14700">Has your disclaimer been approved by legal?*</label>
                                                            <div>
                                                                <p style={{ fontSize: '16px' }}>{compaignData && compaignData.haveApproved.type}</p>
                                                            </div>
                                                        </div>
                                                        {compaignData && compaignData.haveApproved && compaignData.haveApproved.isCheck === 'no' ? <label htmlFor="basic-url" style={{ fontSize: '16px' }}>Legal disclaimer needs to be approved prior to submitting this request as the request will be automatically declined without legal approval.</label> : ""}
                                                        {
                                                            compaignData && compaignData.haveApproved && compaignData.haveApproved.isCheck == 'yes' ?
                                                                <label htmlFor="basic-url" style={{ fontSize: '16px' }}>{compaignData && compaignData.haveApproved && compaignData.haveApproved.insertName ? `Approved by ${compaignData && compaignData.haveApproved && compaignData.haveApproved.insertName}` : ''}</label> :
                                                                <label htmlFor="basic-url" style={{ fontSize: '16px' }}>{compaignData && compaignData.haveApproved && compaignData.haveApproved.isConfirm ? `I confirm that this message does not require legal approval and assume responsibility of any inaccuracies or errors in message` : ''}</label>
                                                        }
                                                    </div>
                                                </div> : ""
                                        }
                                        {compaignData && !compaignData.needLegalDeclaimer ?
                                            <div style={{ marginTop: '16px' }}>
                                                <div className="d-flex flex-column">
                                                    <div style={{ marginTop: '0px' }}>
                                                        <label htmlFor="basic-url" className="size14700">Has your disclaimer been approved by legal?*</label>
                                                        <div>
                                                            <p style={{ fontSize: '16px' }}>{compaignData?.haveApproved?.type}</p>
                                                        </div>
                                                    </div>
                                                    {compaignData?.haveApproved?.isCheck === 'no' && (
                                                        <label htmlFor="basic-url" style={{ fontSize: '16px' }}>
                                                            Legal disclaimer needs to be approved prior to submitting this request as the request will be automatically declined without legal approval.
                                                        </label>
                                                    )}
                                                    {compaignData?.haveApproved?.isCheck === 'yes' ? (
                                                        <label htmlFor="basic-url" style={{ fontSize: '16px' }}>
                                                            {compaignData?.haveApproved?.insertName ? `Approved by ${compaignData?.haveApproved?.insertName}` : ''}
                                                        </label>
                                                    ) : (
                                                        <label htmlFor="basic-url" style={{ fontSize: '16px' }}>
                                                            {compaignData?.haveApproved?.isConfirm ? `I confirm that this message does not require legal approval and assume responsibility for any inaccuracies or errors in the message.` : ''}
                                                        </label>
                                                    )}
                                                </div>

                                            </div> : ""
                                        }
                                    </div>
                                    <div className="col-12 mb-40px">
                                        <BoldListField label="Additional Comments" value={compaignData ? compaignData.additionalComment : ''} />
                                    </div>
                                    <div className="col-12">
                                        {compaignData && compaignData.additionalDocumentation.length > 0 ? (
                                            <div className="d-flex flex-column">
                                                <BoldListField
                                                    label="Any additional documentation to share for this request?"
                                                />
                                                <div className="upload-file-body mt-2">
                                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <img src={DownloadIcon} alt="" style={{ height: '22px', marginRight: '20px' }} />
                                                            {
                                                                compaignData && compaignData.additionalDocumentation.length > 0 ? '' : <p className="size16700 mb-0">No File</p>
                                                            }
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            {
                                                                compaignData && compaignData.additionalDocumentation ? compaignData.additionalDocumentation.map((item, index) => (
                                                                    <div className="d-flex flex-row align-items-center justify-content-end" style={{ position: 'relative', zIndex: '10' }}>
                                                                        <a target="_blank" href={item.url} className="mb-0 design-upload">{getFileNameShort(sliceURL(item.url).slice(14))}</a>
                                                                    </div>
                                                                )) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </> : ""
                        }
                    </div>
                    {/* comments */}
                    {compaignData ? <AdminCommentV2 fetchViewByID={fetchViewByID} compaignData={compaignData} getCampaignId={getCampaignId} fetchViewByIDs={fetchViewByIDs}/> : null}
                </div>
            </div>
        </div>
    )
}

export default ViewFromIntakeV2;